
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { getCSSVariableValue } from "../assets/ts/_utils";
import { useI18n } from "vue-i18n";
import store from "@/presentation/store";
import SwalNotification from "../plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { StatisticModel } from "@/domain/statistic/model/StatisticModel";

export default defineComponent({
  name: "dashboard",
  components: {},
  setup() {
    const { t } = useI18n();
    const statisticController = store.state.ControllersModule.statisticController;
    const swalNotification = new SwalNotification();

    const statistics = ref<StatisticModel>({});

    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-info");
    const lightColor = getCSSVariableValue("--bs-light-info");

    const options = ref<any>([
      {
      chart: {
        fontFamily: "inherit",
        type: "area",
        height: 50,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [baseColor],
      },
      xaxis: {
        categories: [
          new Date().toLocaleDateString()
        ],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        crosshairs: {
          position: "front",
          stroke: {
            color: baseColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return +val + " işlem";
          },
        },
      },
      colors: [lightColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeColor: baseColor,
        strokeWidth: 3,
      }
    }
      ]);

    const series = ref([
      {
        name: "İşlem Sayısı",
        data: [0],
      },
      ]);

    const getStatistics = async () => {
      statisticController
        .getStatistic()
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(statistics.value, response.getValue());
            
            if(statistics.value.weeklyTransactionSummary)
            statistics.value.weeklyTransactionSummary.forEach((transactionSummary) => {

              series.value[0].data.push(Number(transactionSummary.transactionCount));

              const newDate = new Date(
                transactionSummary.date
              ).toLocaleDateString("tr-TR");
              options.value[0].xaxis.categories.push(newDate);
            });
            options.value[0].xaxis.categories.reverse();
            series.value[0].data.reverse();

            options.value[0].xaxis.categories.pop();
            series.value[0].data.pop()

          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      setCurrentPageTitle(t("dashboard.title"));
      getStatistics();
    });

    return {
      options,
      series,
      statistics,
    };
  },
});
